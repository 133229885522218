<template>
  <div class="patient-summary-body">
    <div class="section-1">
      <b-media vertical-align="center">
        <template #aside>
          <b-avatar
            size="32"
            :src="patientInfo.avatar"
            :text="avatarText(patientInfo.patient_id)"
          />
        </template>
        <b-link
          :to="{ name: 'patient-info' }"
          class="font-weight-bold text-nowrap patient-id"
        >
          {{ patientInfo.patient_id }}
        </b-link>
        &nbsp;
        <feather-icon icon="CopyIcon" size="18" @click="copy" />
        <br />
        <small class="text-muted">{{ patientInfo.hospital_id }}</small>
      </b-media>
    </div>
    <div class="normal-section">
      <div class="text-muted">Geburtsdatum</div>
      <div class="margin-small">
        {{ patientInfo.date_of_birth || "&nbsp;" }}
      </div>
    </div>
    <div class="normal-section">
      <div class="text-muted">Implantationsdatum</div>
      <div class="margin-small">
        {{ patientInfo.implantationsdatum_date || "&nbsp;" }}
      </div>
    </div>
    <!-- <div class="normal-section">
				<div class="text-muted">Procedure</div>
				<div class="margin-small">{{ patientInfo.procedure_date || '&nbsp;' }}</div>
			</div> -->
    <div class="action-section">
      <feather-icon
        icon="TrashIcon"
        stroke="#EA5455"
        size="32"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        @click="handleClickDelete"
        v-if="role && role != 'viewer'"
      />
    </div>
  </div>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BMedia,
  BAvatar,
  BButton,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import { mapActions, mapState } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BMedia,
    BAvatar,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      patientInfo: {
        _id: "",
        patient_id: "Patient 1",
        hospital_id: "------",
        date_of_birth: "",
        procedure_date: "",
        implantationsdatum_date: "",
      },
      avatarText,
      roles: JSON.parse(localStorage.getItem("roleData")),
      userData: JSON.parse(localStorage.getItem("userData")),
    };
  },
  mounted() {
    this.getPatientById().catch((err) => {
      this.showToast(
        "danger",
        "Error",
        "An error occured while fetching the patient."
      );
    });
  },
  computed: {
    ...mapState("patient", ["patient", "role"]),
  },
  watch: {
    patient: {
      deep: true,
      handler(v, ov) {
        if (v._id) {
          this.setPatientInfo(v);
          this.checkRole(v.hospital_id);
        }
        if (
          !this.userData.is_admin &&
          !this.roles.find(
            (role) =>
              role.hospital_id == v.hospital_id &&
              (role.role == "editor" || role.role == "viewer")
          )
        ) {
          this.$router.push({ name: "patient-search" });
        }
        // if (v.date_of_birth) {
        //   console.log("state of birthdate changed");
        //   this.setPatientInfo(v);
        // }
      },
    },
  },
  methods: {
    ...mapActions("patient", ["getPatientById", "checkRole"]),
    setPatientInfo(v) {
      this.patientInfo._id = v._id;
      this.patientInfo.patient_id = v.patient_id;
      this.patientInfo.hospital_id = v.hospital ? v.hospital.hospital_id : "";
      this.patientInfo.date_of_birth = v.date_of_birth;
      this.patientInfo.implantationsdatum_date = v.implantationsdatum_date;
    },
    handleClickDelete() {
      this.$swal({
        title: "Delete this patient?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$http
            .delete(`/patients/delete/${this.patientInfo._id}`)
            .then((res) => {
              this.$swal({
                icon: "success",
                title: "Deleted!",
                text: "The patient has been deleted.",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.$router.push({ name: "patient-search" });
            })
            .catch((err) => {
              this.$swal({
                icon: "error",
                title: "Error!",
                text: "An error occured while deleting the patient.",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            });
        }
      });
    },
    copy() {
      this.$copyText(this.patientInfo.patient_id).then(
        () => {
          this.onCopy();
        },
        () => {
          this.onError();
        }
      );
    },
    onCopy() {
      this.showToast("success", "Successfully copied to clipboard", "");
    },
    onError() {
      this.showToast("danger", "Successfully copied to clipboard", "");
    },
    showToast(variant, title, content) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: "BellIcon",
          text: content,
          variant,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.patient-summary-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // padding-right: 4rem;
  // padding-left: 4rem;
  height: 100%;

  .section-1 {
    min-width: 200px;
    .media-body {
      margin-left: 10px;
      a {
        color: #5e5873;
      }
      svg {
        cursor: pointer;
      }
    }
  }

  .margin-small {
    margin-top: 5px;
  }

  .normal-section {
    min-width: 150px;
    text-align: center;
  }

  .action-section {
    min-width: 250px;
    svg {
      float: right;
      cursor: pointer;
    }
  }
}
</style>
